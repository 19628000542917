import { InputText } from '@/components/InputText';
import { FormErrors } from '@/hooks/useForm';
import React from 'react';
import { Badge, Col, FormGroup, Row } from 'reactstrap';
import { SelectAutoComplete } from '@/components/Select';
import { ChecklistCommunicationDay } from '@/model/ChecklistCommunicationDay';
import Btn from '@/components/Button';
import { Checkbox } from '@/components/Checkbox';
import {
  CommunicationDay,
  CommunicationUser,
  ConfigureChecklist,
  FormInputRegister,
} from '../../types';
import { CheckboxContent } from '../Checkbox';

interface StateProps {
  checklistCommunicationId: string;
  formData: FormInputRegister;
  formErrors: FormErrors<FormInputRegister>;
  communicationChecklists: ConfigureChecklist[];
  communicationUsers: CommunicationUser[];
  communicationDays: CommunicationDay[];
  executionTimes: string[];
}

interface DispatchProps {
  onChangeInput: <N extends keyof FormInputRegister, V>(name: N, value: V) => void;
  onChangeChecklist: (value: string) => void;
  onShowChecklist: (checklistCommunicationId: string, value: string) => void;
  onChangeUser: (value: string) => void;
  onChangeDay: (value: string) => void;
  onAddExecutionTime: () => void;
  onChangeExecutionTimes: (index: number, executionTime: string) => void;
  onRemoveExecutionTime: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const RegisterContent: React.FC<Props> = ({
  checklistCommunicationId,
  formData,
  formErrors,
  onChangeInput,
  communicationChecklists,
  onChangeChecklist,
  onShowChecklist,
  communicationUsers,
  onChangeUser,
  communicationDays,
  onChangeDay,
  executionTimes,
  onAddExecutionTime,
  onChangeExecutionTimes,
  onRemoveExecutionTime,
}): JSX.Element => {
  const checklistOptions = communicationChecklists.map(data => ({
    value: `${data.storeId};${data.departmentId}`,
    label: `${data.storeName} - ${data.departmentName}`,
    checked: data.checked,
  }));

  const getDayLable = (day: ChecklistCommunicationDay): string => {
    let value = '';
    switch (day) {
      case ChecklistCommunicationDay.MONDAY:
        value = 'Segunda';
        break;
      case ChecklistCommunicationDay.TUESDAY:
        value = 'Terça';
        break;
      case ChecklistCommunicationDay.WEDNESDAY:
        value = 'Quarta';
        break;
      case ChecklistCommunicationDay.THURSDAY:
        value = 'Quinta';
        break;
      case ChecklistCommunicationDay.FRIDAY:
        value = 'Sexta';
        break;
      case ChecklistCommunicationDay.SATURDAY:
        value = 'Sábado';
        break;
      case ChecklistCommunicationDay.SUNDAY:
        value = 'Domingo';
        break;
      default:
        value = 'Segunda';
        break;
    }
    return value;
  };

  const dayOptions = communicationDays.map(data => ({
    value: data.day,
    label: getDayLable(data.day),
    selected: data.selected,
  }));

  const userOptions = communicationUsers.map(data => ({
    value: data.userId,
    label: data.userName,
    checked: data.checked,
  }));

  const typeOptions = [
    {
      label: 'E-mail',
      value: 'EMAIL',
    },
    {
      label: 'Whatsapp',
      value: 'WHATSAPP',
    },
    {
      label: 'Push',
      value: 'PUSH',
    },
  ];

  const statusOptions = [
    {
      label: 'Ativo',
      value: 'ACTIVE',
    },
    {
      label: 'Inativo',
      value: 'INACTIVE',
    },
  ];
  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="description"
              label="Descrição"
              placeholder="Informe a descrição"
              value={formData.description}
              onChange={e => onChangeInput('description', e.target.value)}
              error={formErrors.description && formErrors.description[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectAutoComplete
              label="Tipo"
              options={typeOptions}
              name="type"
              placeholder="Selecione o tipo"
              defaultValue={formData.type}
              onChange={value => onChangeInput('type', value)}
              error={formErrors.type && formErrors.type[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <CheckboxContent
              checklistCommunicationId={checklistCommunicationId}
              label="Checklists"
              name="checklists"
              options={checklistOptions}
              onChange={onChangeChecklist}
              onButtonClick={value => onShowChecklist(checklistCommunicationId, value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <div className={`mb-4 flex-column`}>
              <label htmlFor="days" className="input-label">
                Dias
              </label>
              <div className="m-t-10 custom-radio-ml">
                {dayOptions &&
                  dayOptions.length > 0 &&
                  dayOptions.map(data => (
                    <Badge
                      key={data.value}
                      className={
                        data.selected ? 'badge rounded-pill' : 'badge rounded-pill txt-dark'
                      }
                      color={data.selected ? 'success' : 'light'}
                      pill={true}
                      style={{ cursor: 'pointer' }}
                      onClick={() => onChangeDay(data.value)}
                    >
                      {data.label}
                    </Badge>
                  ))}
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-2">
            <div className={`flex-column input-component`}>
              <label htmlFor="executions" className="input-label">
                Execuções
              </label>
            </div>
          </FormGroup>
        </Col>
      </Row>
      {executionTimes &&
        executionTimes.length > 0 &&
        executionTimes.map((data, index) => (
          <Row key={`${index}-${data}`}>
            <Col md={6}>
              <InputText
                name={`${index}-executionTime-${data}`}
                label=""
                placeholder="Horário de execução"
                type="time"
                value={data}
                onChange={e => onChangeExecutionTimes(index, e.target.value)}
              />
            </Col>
            <Col md={6}>
              {index === 0 ? (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onAddExecutionTime();
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  +
                </Btn>
              ) : (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onRemoveExecutionTime(index);
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  -
                </Btn>
              )}
            </Col>
          </Row>
        ))}
      <Row>
        <Col md={8}>
          <FormGroup className="mt-3 mb-2">
            <Checkbox label="Usuários" name="users" options={userOptions} onChange={onChangeUser} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectAutoComplete
              label="Status"
              options={statusOptions}
              name="status"
              placeholder="Selecione o status"
              defaultValue={formData.status}
              onChange={value => onChangeInput('status', value)}
              error={formErrors.status && formErrors.status[0]}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
