import { ChecklistCommunication } from '@/model/ChecklistCommunication';
import { ChecklistCommunicationDay } from '@/model/ChecklistCommunicationDay';
import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  FILTER,
  REGISTER,
  CHECKLIST,
  CAMERA,
  PRODUCT,
}

export interface ModalContent {
  value: ShouldShowModal;
  newTitleModal: string | React.ReactNode;
  selectedId?: string;
  storeId?: string;
  departmentId?: string;
  cameraId?: string;
  cameraName?: string;
  toogleModal: boolean;
}

export interface FormInputFilter {
  filterSearch: string;
  inputSearch: string;
}

export interface FormInputRegister {
  id: string;
  description: string;
  type: string;
  status: string;
}

export interface ConfigureChecklist {
  storeId: string;
  storeName: string;
  departmentId: string;
  departmentName: string;
  checked: boolean;
}

export interface CommunicationUser {
  userId: string;
  userName: string;
  checked: boolean;
}

export interface CommunicationDay {
  day: ChecklistCommunicationDay;
  selected: boolean;
}

export const initialCommunicationDays: CommunicationDay[] = [
  {
    day: ChecklistCommunicationDay.MONDAY,
    selected: false,
  },
  {
    day: ChecklistCommunicationDay.TUESDAY,
    selected: false,
  },
  {
    day: ChecklistCommunicationDay.WEDNESDAY,
    selected: false,
  },
  {
    day: ChecklistCommunicationDay.THURSDAY,
    selected: false,
  },
  {
    day: ChecklistCommunicationDay.FRIDAY,
    selected: false,
  },
  {
    day: ChecklistCommunicationDay.SATURDAY,
    selected: false,
  },
  {
    day: ChecklistCommunicationDay.SUNDAY,
    selected: false,
  },
];

export interface DataRow extends ChecklistCommunication {
  actions: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Descrição',
    selector: row => row.description,
  },
  {
    name: 'Status',
    selector: row => row.status,
  },
  {
    name: 'Ações',
    selector: row => row.actions,
    width: '90px',
  },
];

export interface FormInputCamera {
  storeId: string;
  storeName: string;
  departmentId: string;
  departmentName: string;
  cameraId: string;
}

export interface FormInputProduct {
  storeId: string;
  storeName: string;
  departmentId: string;
  departmentName: string;
  cameraId: string;
  cameraName: string;
}
