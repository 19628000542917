import SvgIcon from '@/components/SvgIcon';
import React from 'react';

interface OptionProps {
  value: string;
  label: string;
  checked: boolean;
}

interface StateProps {
  checklistCommunicationId: string;
  label: string;
  name: string;
  error?: string;
  options: OptionProps[];
}

interface DispatchProps {
  onChange: (value: string) => void;
  onButtonClick: (value: string) => void;
}

type Props = StateProps & DispatchProps;

export const CheckboxContent: React.FC<Props> = ({
  checklistCommunicationId,
  label,
  name,
  error,
  options,
  onChange,
  onButtonClick,
}): JSX.Element => (
  <div className={`mb-4 flex-column`}>
    {label && (
      <div className="d-flex flex-column-reverse">
        <label htmlFor={name} className="input-label m-0">
          {label}
        </label>
        {error && (
          <span role="alert" className="alert-error">
            {error}
          </span>
        )}
      </div>
    )}
    <div className="m-t-15 custom-radio-ml">
      {options.map((option: OptionProps) => {
        const idRandom = Math.random().toString(36).substr(2, 9);
        return (
          <div key={option.value} style={{ display: 'inline' }}>
            <div className="checkbox">
              <div style={{ width: '90%', display: 'inline-block' }}>
                <input
                  type="checkbox"
                  name={name}
                  id={`${option.value}-${idRandom}`}
                  value={option.value}
                  onChange={() => onChange(option.value)}
                  checked={option.checked}
                />
                <label htmlFor={`${option.value}-${idRandom}`}>{option.label}</label>
              </div>
              {checklistCommunicationId && (
                <div style={{ width: '10%', textAlign: 'right', display: 'inline-block' }}>
                  <SvgIcon
                    iconId={'stroke-store'}
                    className="mr-4 svg-icon action-icon"
                    style={{
                      height: '18px',
                      width: '18px',
                    }}
                    onClick={() => onButtonClick(option.value)}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);
