import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale/pt-BR';
import { Form } from 'reactstrap';
import { ChecklistExecutionFilter } from '@/model/ChecklistExecutionFilter';

registerLocale('ptBR', ptBR);

interface StateProps {
  filter: ChecklistExecutionFilter;
}

export const FilterContent: React.FC<StateProps> = ({ filter }): JSX.Element => {
  const handleChange = (date: [Date | null, Date | null]): void => {
    if (date) {
      console.log(date);
    }
  };

  return (
    <div className="filter-modal-content">
      <Form noValidate={true} onSubmit={(e): void => e.preventDefault()}>
        <div className="datepicker-here" data-language="pt-BR">
          <DatePicker
            className="form-control digits"
            selected={filter.startDate}
            onChange={date => handleChange(date)}
            startDate={filter.startDate}
            endDate={filter.endDate}
            selectsRange
            locale="ptBR"
            inline
          />
        </div>
      </Form>
    </div>
  );
};
