import React, { useEffect, useState } from 'react';
import { LoaderStatus } from '@/components/Loader';
import { useDialog } from '@/hooks/useDialog';
import { useForm } from '@/hooks/useForm';
import * as validators from '@/utils/validators';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Page } from '@/model/Page';
import { ChecklistCommunication } from '@/model/ChecklistCommunication';
import { Checklist } from '@/model/Checklist';
import { getCameras, getChecklists } from '@/feature/configureChecklist/services';
import { CommunicationType } from '@/model/CommunicationType';
import dayjs from 'dayjs';
import { ProductDepartment } from '@/model/ProductDepartment';
import { User } from '@/model/User';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { StatusType } from '@/model/StatusType';
import { CustomError } from '@/model/CustomError';
import { getErrorMessage } from '@/utils/errorMessage';
import { Product } from '@/model/Product';
import { Camera } from '@/model/Camera';
import { ChecklistCommunicationCamera } from '@/model/ChecklistCommunicationCamera';
import {
  deleteChecklistCommunication,
  getChecklist,
  getChecklistCommunication,
  getChecklistCommunicationCameras,
  getUsers,
  pageChecklistCommunication,
  removeChecklistCommunicationCamera,
  saveChecklistCommunication,
  saveChecklistCommunicationCamera,
} from '../services';
import {
  CommunicationDay,
  CommunicationUser,
  ConfigureChecklist,
  FormInputFilter,
  FormInputRegister,
  FormInputCamera,
  initialCommunicationDays,
  ShouldShowModal,
  FormInputProduct,
  ModalContent,
} from '../types';
import { CommunicationChecklistUI } from './ui';
import { DeleteContent } from '../componets/DeleteContent';

export const CommunicationChecklistScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.FILTER);
  const [page, setPage] = useState<Page<ChecklistCommunication, ChecklistCommunication>>({
    page: 1,
    pageSize: 10,
  });
  const [checklistCommunicationId, setChecklistCommunicationId] = useState<string>();
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [communicationChecklists, setCommunicationChecklists] = useState<ConfigureChecklist[]>([]);
  const [communicationUsers, setCommunicationUsers] = useState<CommunicationUser[]>([]);
  const [communicationDays, setCommunicationDays] =
    useState<CommunicationDay[]>(initialCommunicationDays);
  const [executionTimes, setExecutionTimes] = useState<string[]>(['']);
  const [checklist, setChecklist] = useState<Checklist>();
  const [registerTitle, setRegisterTitle] = useState<string>();
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [checklistTitle, setChecklistTitle] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [products, setProducts] = useState<Product[]>([]);
  const [registerProducts, setRegisterProducts] = useState<Product[]>([]);

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleOnFetchChecklistCommunications = async (
    newPage: Page<ChecklistCommunication, ChecklistCommunication>,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await pageChecklistCommunication(newPage);
      setPage(response);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeInput: onChangeInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm<FormInputFilter>({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      filterSearch: [validators.required],
    },
  });

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    onChangeInput: onChangeInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm<FormInputRegister>({
    initialData: {
      id: '',
      description: '',
      type: '',
      status: 'ACTIVE',
    },
    validators: {
      description: [validators.required],
      type: [validators.required],
      status: [validators.required],
    },
  });

  const {
    formData: formDataCamera,
    formErrors: formErrorsCamera,
    onChangeInput: onChangeInputCamera,
    isFormValid: isFormValidCamera,
    resetForm: resetFormCamera,
  } = useForm<FormInputCamera>({
    initialData: {
      storeId: '',
      storeName: '',
      departmentId: '',
      departmentName: '',
      cameraId: '',
    },
    validators: {
      storeId: [validators.required],
      storeName: [validators.required],
      departmentId: [validators.required],
      departmentName: [validators.required],
      cameraId: [validators.required],
    },
  });

  const {
    formData: formDataProduct,
    formErrors: formErrorsProduct,
    onChangeInput: onChangeInputProduct,
    isFormValid: isFormValidProduct,
    resetForm: resetFormProduct,
  } = useForm<FormInputProduct>({
    initialData: {
      storeId: '',
      storeName: '',
      departmentId: '',
      departmentName: '',
      cameraId: '',
      cameraName: '',
    },
    validators: {
      storeId: [validators.required],
      storeName: [validators.required],
      departmentId: [validators.required],
      departmentName: [validators.required],
      cameraId: [validators.required],
      cameraName: [validators.required],
    },
  });

  const handleOnGetChecklists = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getChecklists();
      setChecklists(response);
      const newCommunicationChecklists: ConfigureChecklist[] = [];
      if (response && response.length > 0) {
        response.forEach(data => {
          if (data.departments && data.departments.length > 0) {
            data.departments.forEach(department => {
              const newCommunicationChecklist: ConfigureChecklist = {
                storeId: data.store.id,
                storeName: data.store.name,
                departmentId: department.id,
                departmentName: department.name,
                checked: false,
              };
              newCommunicationChecklists.push(newCommunicationChecklist);
            });
          }
        });
      }
      setCommunicationChecklists(newCommunicationChecklists);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetUsers = async (): Promise<void> => {
    try {
      const response = await getUsers();
      if (response && response.length > 0) {
        const newCommunicationUsers: CommunicationUser[] = [];
        response.forEach(data => {
          const newCommunicationUser: CommunicationUser = {
            userId: data.id,
            userName: data.name,
            checked: false,
          };
          newCommunicationUsers.push(newCommunicationUser);
        });
        setCommunicationUsers(newCommunicationUsers);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetChecklistCommunication = async (selectedId: string): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const responseChecklist = await getChecklists();
      const responseUsers = await getUsers();
      const response = await getChecklistCommunication(selectedId);
      onChangeInputRegister('id', response.id);
      onChangeInputRegister('description', response.description);
      onChangeInputRegister('type', response.type);
      onChangeInputRegister('status', response.status);
      const newCommunicationChecklists: ConfigureChecklist[] = [];
      if (responseChecklist && responseChecklist.length > 0) {
        responseChecklist.forEach(data => {
          if (data.departments && data.departments.length > 0) {
            data.departments.forEach(department => {
              let checked = false;
              if (response.checklists && response.checklists.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let c = 0; c < response.checklists.length; c++) {
                  if (response.checklists[c].store.id === data.store.id) {
                    if (
                      response.checklists[c].departments &&
                      response.checklists[c].departments.length > 0
                    ) {
                      // eslint-disable-next-line no-plusplus
                      for (let d = 0; d < response.checklists[c].departments.length; d++) {
                        if (response.checklists[c].departments[d].id === department.id) {
                          checked = true;
                          break;
                        }
                      }
                    }
                    break;
                  }
                }
              }

              const newCommunicationChecklist: ConfigureChecklist = {
                storeId: data.store.id,
                storeName: data.store.name,
                departmentId: department.id,
                departmentName: department.name,
                checked,
              };
              newCommunicationChecklists.push(newCommunicationChecklist);
            });
          }
        });
      }
      setCommunicationChecklists(newCommunicationChecklists);
      const newCommunicationDays: CommunicationDay[] = [];
      communicationDays.forEach(data => {
        let selected = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < response.days.length; i++) {
          if (response.days[i] === data.day) {
            selected = true;
            break;
          }
        }

        newCommunicationDays.push({ ...data, selected });
      });
      setCommunicationDays(newCommunicationDays);
      const newExecutionTimes: string[] = [];
      response.executionTimes.forEach(data => {
        const strDate = data as unknown as string;
        newExecutionTimes.push(strDate.substring(0, 5));
      });
      setExecutionTimes(newExecutionTimes);
      const newCommunicationUsers: CommunicationUser[] = [];
      responseUsers.forEach(data => {
        let checked = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < response.users.length; i++) {
          if (data.id === response.users[i].id) {
            checked = true;
            break;
          }
        }
        const newCommunicationUser: CommunicationUser = {
          userId: data.id,
          userName: data.name,
          checked,
        };
        newCommunicationUsers.push(newCommunicationUser);
      });
      setCommunicationUsers(newCommunicationUsers);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetChecklist = async (
    id: string,
    storedId: string,
    departmentId: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getChecklist(id, storedId, departmentId);
      setChecklist(response);
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetCameras = async (
    selectedId: string,
    storedId: string,
    departmentId: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const newCameras: Camera[] = [];
      const responseCameras = await getCameras(storedId, departmentId);
      const responseCommunicationCameras = await getChecklistCommunicationCameras(
        selectedId,
        storedId,
        departmentId,
      );
      if (responseCameras && responseCameras.length > 0) {
        responseCameras.forEach(data => {
          let exists = false;
          if (responseCommunicationCameras && responseCommunicationCameras.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < responseCommunicationCameras.length; i++) {
              if (data.id === responseCommunicationCameras[i].id) {
                exists = true;
                break;
              }
            }
          }
          if (!exists) {
            newCameras.push(data);
          }
        });
      }
      setCameras(newCameras);
      onChangeInputCamera('storeId', checklist?.store.id);
      onChangeInputCamera('storeName', checklist?.store.name);
      onChangeInputCamera('departmentId', checklist?.departments[0].id);
      onChangeInputCamera('departmentName', checklist?.departments[0].name);
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShouldShowModal = async (content: ModalContent): Promise<void> => {
    try {
      setShouldShowModal(content.value);
      onChangeTitle(content.newTitleModal);
      if (
        content.value === ShouldShowModal.FILTER ||
        (content.value === ShouldShowModal.REGISTER && !content.selectedId)
      ) {
        await handleOnGetChecklists();
        await handleOnGetUsers();
      } else if (content.value === ShouldShowModal.REGISTER && content.selectedId) {
        setChecklistCommunicationId(content.selectedId);
        setRegisterTitle(content.newTitleModal as string);
        handleOnGetChecklistCommunication(content.selectedId);
      } else if (
        content.value === ShouldShowModal.CHECKLIST &&
        content.selectedId &&
        content.storeId &&
        content.departmentId
      ) {
        await handleOnGetChecklist(content.selectedId, content.storeId, content.departmentId);
      } else if (
        content.value === ShouldShowModal.CAMERA &&
        content.selectedId &&
        content.storeId &&
        content.departmentId
      ) {
        await handleOnGetCameras(content.selectedId, content.storeId, content.departmentId);
      }
      if (content.toogleModal) {
        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnChangeChecklist = (value: string): void => {
    const split = value.split(';');
    const storedId = split[0];
    const departmentId = split[1];
    const newCommunicationChecklists: ConfigureChecklist[] = [];
    communicationChecklists.forEach(data => {
      if (data.storeId === storedId && data.departmentId === departmentId) {
        newCommunicationChecklists.push({ ...data, checked: !data.checked });
      } else {
        newCommunicationChecklists.push(data);
      }
    });
    setCommunicationChecklists(newCommunicationChecklists);
  };

  const handleOnShowChecklist = (id: string, value: string): void => {
    setChecklistCommunicationId(id);
    const split = value.split(';');
    const storeId = split[0];
    const departmentId = split[1];
    handleOnShouldShowModal({
      value: ShouldShowModal.CHECKLIST,
      newTitleModal: 'ABC',
      selectedId: id,
      storeId,
      departmentId,
      toogleModal: false,
    });
  };

  const handleOnChangeUser = (value: string): void => {
    const newCommunicationUsers: CommunicationUser[] = [];
    communicationUsers.forEach(data => {
      if (data.userId === value) {
        newCommunicationUsers.push({ ...data, checked: !data.checked });
      } else {
        newCommunicationUsers.push(data);
      }
    });
    setCommunicationUsers(newCommunicationUsers);
  };

  const handleOnChangeDay = (value: string): void => {
    const newCommunicationDays: CommunicationDay[] = [];
    communicationDays.forEach(data => {
      if (data.day === value) {
        newCommunicationDays.push({ ...data, selected: !data.selected });
      } else {
        newCommunicationDays.push(data);
      }
    });
    setCommunicationDays(newCommunicationDays);
  };

  const handleOnAddExecutionTime = (): void => {
    const newExecutionTimes: string[] = [];
    executionTimes.forEach(data => {
      newExecutionTimes.push(data);
    });
    newExecutionTimes.push('');
    setExecutionTimes(newExecutionTimes);
  };

  const handleOnChangeExecutionTimes = (index: number, executionTime: string): void => {
    const newExecutionTimes: string[] = [];
    executionTimes.forEach((data, i) => {
      if (i === index) {
        newExecutionTimes.push(executionTime);
      } else {
        newExecutionTimes.push(data);
      }
    });
    setExecutionTimes(newExecutionTimes);
  };

  const handleOnRemoveExecutionTime = (index: number): void => {
    const newExecutionTimes: string[] = [];
    executionTimes.forEach((data, i) => {
      if (i !== index) {
        newExecutionTimes.push(data);
      }
    });
    setExecutionTimes(newExecutionTimes);
  };

  const hasChecklists = (): boolean => {
    let b = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < communicationChecklists.length; i++) {
      if (communicationChecklists[i].checked) {
        b = true;
        break;
      }
    }
    if (!b) {
      toast.error('Selecionar checklist');
    }
    return b;
  };

  const hasDay = (): boolean => {
    let b = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < communicationDays.length; i++) {
      if (communicationDays[i].selected) {
        b = true;
        break;
      }
    }
    if (!b) {
      toast.error('Selecionar dias');
    }
    return b;
  };

  const hasExecutionTime = (): boolean => {
    const b = executionTimes.length > 0;
    if (!b) {
      toast.error('Informar execuções');
    }
    return b;
  };

  const hasUser = (): boolean => {
    let b = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < communicationUsers.length; i++) {
      if (communicationUsers[i].checked) {
        b = true;
        break;
      }
    }
    if (!b) {
      toast.error('Selecionar usuários');
    }
    return b;
  };

  const handleOnSave = async (): Promise<void> => {
    if (isFormValidRegister() && hasChecklists() && hasDay() && hasExecutionTime() && hasUser()) {
      try {
        setStatus(LoaderStatus.LOADING);
        const payload = {
          description: formDataRegister.description,
          type: formDataRegister.type as CommunicationType,
          checklists: [],
          days: [],
          executionTimes: [],
          users: [],
          status: formDataRegister.status as StatusType,
        } as unknown as ChecklistCommunication;
        if (formDataRegister.id) {
          payload.id = formDataRegister.id;
        }
        const newChecklists: Checklist[] = [];
        communicationChecklists.forEach(data => {
          if (data.checked) {
            let foundChecklist = false;
            // eslint-disable-next-line no-plusplus
            for (let c = 0; c < newChecklists.length; c++) {
              if (newChecklists[c].store.id === data.storeId) {
                foundChecklist = true;
                newChecklists[c].departments.push({
                  id: data.departmentId,
                  name: data.departmentName,
                } as ProductDepartment);
                break;
              }
            }
            if (!foundChecklist) {
              const newChecklist: Checklist = {
                store: {
                  id: data.storeId,
                  name: data.storeName,
                },
                departments: [],
              };
              newChecklist.departments.push({
                id: data.departmentId,
                name: data.departmentName,
              } as ProductDepartment);
              newChecklists.push(newChecklist);
            }
          }
        });
        payload.checklists = newChecklists;
        communicationDays.forEach(data => {
          if (data.selected) {
            payload.days.push(data.day);
          }
        });

        executionTimes.forEach(data => {
          if (data && data.trim().length > 0) {
            payload.executionTimes.push(
              dayjs(`2024-01-01 ${data}:00`, 'DD/MM/YYYY HH:mm:ss').toDate(),
            );
          }
        });

        communicationUsers.forEach(data => {
          if (data.checked) {
            payload.users.push({
              id: data.userId,
              name: data.userName,
            } as User);
          }
        });
        await saveChecklistCommunication(payload);
        resetFormRegister();
        onToggle();
        const newPage = {
          page: page.page,
          pageSize: page.pageSize,
          entity: page.entity,
          order: page.order,
          sort: page.sort,
        };
        handleOnFetchChecklistCommunications(newPage);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnCloseChecklist = (): void => {
    setChecklist(undefined);
    setCameras([]);
    resetFormCamera();
    resetFormProduct();
    handleOnShouldShowModal({
      value: ShouldShowModal.REGISTER,
      newTitleModal: registerTitle,
      toogleModal: false,
    });
    setRegisterTitle(undefined);
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnCloseCamera = (storeId?: string, departmentId?: string): void => {
    setCameras([]);
    resetFormCamera();
    resetFormProduct();
    handleOnShouldShowModal({
      value: ShouldShowModal.CHECKLIST,
      newTitleModal: checklistTitle,
      selectedId: checklistCommunicationId,
      storeId,
      departmentId,
      toogleModal: false,
    });
    setChecklistTitle(undefined);
  };

  const handleOnRemoveCamera = async (
    storeId: string,
    departmentId: string,
    cameraId: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      await removeChecklistCommunicationCamera(
        checklistCommunicationId as string,
        storeId,
        departmentId,
        cameraId,
      );
      handleOnClose();
      handleOnCloseCamera(storeId, departmentId);
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShowDeleteCamera = (
    storeId: string,
    departmentId: string,
    cameraId: string,
  ): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnRemoveCamera(storeId, departmentId, cameraId),
        },
      ],
    });
  };

  const handleOnSaveCamera = async (): Promise<void> => {
    if (isFormValidCamera()) {
      const payload = {
        checklistCommunicationId,
        storeId: formDataCamera.storeId,
        departmentId: formDataCamera.departmentId,
        cameraId: formDataCamera.cameraId,
      } as ChecklistCommunicationCamera;
      try {
        setStatus(LoaderStatus.LOADING);
        await saveChecklistCommunicationCamera(payload);
        await handleOnGetChecklists();
        handleOnCloseCamera(formDataCamera.storeId, formDataCamera.departmentId);
        resetFormCamera();
      } catch (error) {
        const err = error as AxiosError<CustomError>;
        toast.error(getErrorMessage(err));
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnRemoveProduct = async (
    storeId: string,
    departmentId: string,
    cameraId: string,
    productId: string,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      console.log(storeId, departmentId, cameraId, productId);
      // await removeProduct(storeId, departmentId, cameraId, productId);
      await handleOnGetChecklists();
      handleOnClose();
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShowDeleteProduct = (
    storeId: string,
    departmentId: string,
    cameraId: string,
    productId: string,
  ): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> =>
            handleOnRemoveProduct(storeId, departmentId, cameraId, productId),
        },
      ],
    });
  };

  const handleOnAddRegisterProduct = (): void => {
    const newRegisterProducts: Product[] = [];
    const newRegisterProduct = {} as Product;
    registerProducts.forEach(data => {
      newRegisterProducts.push(data);
    });
    newRegisterProducts.push(newRegisterProduct);
    setRegisterProducts(newRegisterProducts);
  };

  const handleOnChangeRegisterProducts = (index: number, productId: string): void => {
    const newRegisterProducts: Product[] = [];
    registerProducts.forEach((data, i) => {
      if (i === index) {
        newRegisterProducts.push({ id: productId } as Product);
      } else {
        newRegisterProducts.push(data);
      }
    });
    setRegisterProducts(newRegisterProducts);
  };

  const handleOnRemoveRegisterProduct = (index: number): void => {
    const newRegisterProducts: Product[] = [];
    registerProducts.forEach((data, i) => {
      if (i !== index) {
        newRegisterProducts.push(data);
      }
    });
    setRegisterProducts(newRegisterProducts);
  };

  const handleOnSaveProduct = async (): Promise<void> => {
    if (isFormValidProduct()) {
      let existswithoutId = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < registerProducts.length; i++) {
        if (!registerProducts[i] || !registerProducts[i].id) {
          existswithoutId = true;
          break;
        }
      }
      if (existswithoutId) {
        toast.warn('Existe registro sem produto selecionado!');
      } else {
        try {
          setStatus(LoaderStatus.LOADING);
          /*
          await saveProducts(
            formDataProduct.storeId,
            formDataProduct.departmentId,
            formDataProduct.cameraId,
            registerProducts,
          );
          */
          await handleOnGetChecklists();
          resetFormProduct();
          onToggle();
        } catch (error) {
          const err = error as AxiosError<CustomError>;
          toast.error(getErrorMessage(err));
        } finally {
          setStatus(LoaderStatus.DEFAULT);
        }
      }
    }
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValidFilter()) {
      /*
          const entity = {} as User;
          if (formDataFilter.filterSearch === 'name') {
            entity.name = formDataFilter.inputSearch;
          } else if (formDataFilter.filterSearch === 'status') {
            const statusType = toStatusType(formDataFilter.inputSearch);
            if (statusType) {
              entity.status = statusType;
            }
          }
          const newPage: Page<User, User> = {
            page: 1,
            pageSize: 10,
            entity,
          };
          onToggle();
          handleOnFetchEvents(newPage);
          */
    }
  };

  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
    resetFormFilter();
    handleOnFetchChecklistCommunications({
      page: 1,
      pageSize: 10,
    });
  };

  const handleOnPaginationChange = async (newPage: number): Promise<void> => {
    handleOnFetchChecklistCommunications({
      ...page,
      page: newPage,
    });
  };

  const handleOnConfirmDelete = async (
    checklistCommunication: ChecklistCommunication,
  ): Promise<void> => {
    try {
      await deleteChecklistCommunication(checklistCommunication.id);
      toast.success('PDV excluído com sucesso!');
      handleOnClose();
      handleOnFetchChecklistCommunications(page);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnShowDelete = (checklistCommunication: ChecklistCommunication): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDelete(checklistCommunication),
        },
      ],
    });
  };

  useEffect(() => {
    handleOnFetchChecklistCommunications(page);
  }, []);

  return (
    <CommunicationChecklistUI
      status={status}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      checklists={checklists}
      checklistCommunicationId={checklistCommunicationId}
      formDataRegister={formDataRegister}
      onChangeInputRegister={onChangeInputRegister}
      formErrorsRegister={formErrorsRegister}
      communicationChecklists={communicationChecklists}
      onChangeChecklist={handleOnChangeChecklist}
      onShowChecklist={handleOnShowChecklist}
      communicationUsers={communicationUsers}
      onChangeUser={handleOnChangeUser}
      communicationDays={communicationDays}
      onChangeDay={handleOnChangeDay}
      executionTimes={executionTimes}
      onAddExecutionTime={handleOnAddExecutionTime}
      onChangeExecutionTimes={handleOnChangeExecutionTimes}
      onRemoveExecutionTime={handleOnRemoveExecutionTime}
      onSave={handleOnSave}
      checklist={checklist}
      onCloseChecklist={handleOnCloseChecklist}
      onShowDeleteCamera={handleOnShowDeleteCamera}
      onShowDeleteProduct={handleOnShowDeleteProduct}
      formDataCamera={formDataCamera}
      onChangeInputCamera={onChangeInputCamera}
      formErrorsCamera={formErrorsCamera}
      cameras={cameras}
      onSaveCamera={handleOnSaveCamera}
      onCloseCamera={handleOnCloseCamera}
      formDataProduct={formDataProduct}
      formErrorsProduct={formErrorsProduct}
      onChangeInputProduct={onChangeInputProduct}
      products={products}
      registerProducts={registerProducts}
      onAddRegisterProduct={handleOnAddRegisterProduct}
      onChangeRegisterProducts={handleOnChangeRegisterProducts}
      onRemoveRegisterProduct={handleOnRemoveRegisterProduct}
      onSaveProduct={handleOnSaveProduct}
      formDataFilter={formDataFilter}
      onChangeInputFilter={onChangeInputFilter}
      formErrorsFilter={formErrorsFilter}
      onClearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      currentPage={page}
      onPaginationChange={handleOnPaginationChange}
      onShowDelete={handleOnShowDelete}
    />
  );
};
