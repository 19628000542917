import React from 'react';

interface OptionProps {
  value: string;
  label: string;
  checked: boolean;
}

interface StateProps {
  label: string;
  name: string;
  error?: string;
  options: OptionProps[];
}

interface DispatchProps {
  onChange: (value: string) => void;
}

type Props = StateProps & DispatchProps;

export const Checkbox: React.FC<Props> = ({
  label,
  name,
  error,
  options,
  onChange,
}): JSX.Element => (
  <div className={`mb-4 flex-column`}>
    {label && (
      <div className="d-flex flex-column-reverse">
        <label htmlFor={name} className="input-label m-0">
          {label}
        </label>
        {error && (
          <span role="alert" className="alert-error">
            {error}
          </span>
        )}
      </div>
    )}
    <div className="m-t-15 custom-radio-ml">
      {options.map((option: OptionProps) => {
        const idRandom = Math.random().toString(36).substr(2, 9);
        return (
          <div key={option.value} className="checkbox">
            <input
              type="checkbox"
              name={name}
              id={`${option.value}-${idRandom}`}
              value={option.value}
              onChange={() => onChange(option.value)}
              checked={option.checked}
            />
            <label htmlFor={`${option.value}-${idRandom}`}>{option.label}</label>
          </div>
        );
      })}
    </div>
  </div>
);
