import { ChecklistExecutionEvent } from '@/model/ChecklistExecutionEvent';
import { ChecklistExecutionFilter } from '@/model/ChecklistExecutionFilter';
import api from '@/services/api';

export const getChecklistExecutions = async (
  filter: ChecklistExecutionFilter,
): Promise<ChecklistExecutionEvent> => {
  const { data } = await api.post<ChecklistExecutionEvent>('/checklist/v1/execution', filter);
  return data;
};
