import React, { Fragment } from 'react';

// eslint-disable-next-line no-shadow
export enum LoaderStatus {
  DEFAULT,
  LOADING,
}

interface LoaderProps {
  status: LoaderStatus;
}

const Loader: React.FC<LoaderProps> = ({ status }): JSX.Element => (
  <Fragment>
    <div
      className={`loader-wrapper ${status === LoaderStatus.LOADING ? '' : 'loderhide'}`}
      style={{ zIndex: 10000 }}
    >
      <div className="loader-index">
        <span></span>
      </div>
      <svg>
        <defs></defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="11" result="blur"></feGaussianBlur>
          <feColorMatrix
            in="blur"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
            result="goo"
          ></feColorMatrix>
        </filter>
      </svg>
    </div>
  </Fragment>
);

export default Loader;
