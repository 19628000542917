import React, { useEffect, useState } from 'react';
import { LoaderStatus } from '@/components/Loader';
import { ChecklistExecutionFilter } from '@/model/ChecklistExecutionFilter';
import { ChecklistExecutionStore } from '@/model/ChecklistExecutionStore';
import { AxiosError } from 'axios';
import { CustomError } from '@/model/CustomError';
import { getErrorMessage } from '@/utils/errorMessage';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import { getChecklistExecutions } from '../services';
import { ExecutionChecklistUI } from './ui';
import { ShouldShowModal } from '../types';

export const ExecutionChecklistScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState(ShouldShowModal.filter);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useState<ChecklistExecutionFilter>({} as ChecklistExecutionFilter);
  const [amountExecutions, setAmountExecutions] = useState<number>(0);
  const [checklistExecutionStore, setChecklistExecutionStore] = useState<ChecklistExecutionStore[]>(
    [],
  );

  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const handleOnFetchExecutions = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getChecklistExecutions(filter);

      if (response.stores && response.stores.length > 0) {
        setChecklistExecutionStore(response.stores);
        let count = 0;
        response.stores.forEach(store => {
          if (store.departments && store.departments.length > 0) {
            store.departments.forEach(department => {
              if (department.cameras && department.cameras.length > 0) {
                department.cameras.forEach(camera => {
                  if (camera.events && camera.events.length > 0) {
                    count += camera.events.length;
                    camera.events.forEach(event => {
                      const split = event.eventDate.split(' ');
                      const date = `${split[0]} às ${split[1].substring(0, 5)}`;
                      console.log(date);
                    });
                  }
                });
              }
            });
          }
        });
        setAmountExecutions(count);
      }
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }): Promise<void> => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  useEffect(() => {
    handleOnFetchExecutions();
  }, []);
  return (
    <ExecutionChecklistUI
      status={status}
      title={title}
      visible={visible}
      shouldShowModal={shouldShowModal}
      onToggle={onToggle}
      filter={filter}
      amountExecutions={amountExecutions}
      onShouldShowModal={handleOnShouldShowModal}
      clearFilter={console.log}
      onFilter={console.log}
      checklistExecutionStore={checklistExecutionStore}
    />
  );
};
