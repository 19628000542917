import Breadcrumbs from '@/components/Breadcrumbs';
import H5 from '@/components/H5';
import Loader, { LoaderStatus } from '@/components/Loader';
import { ChecklistExecutionStore } from '@/model/ChecklistExecutionStore';
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SvgIcon from '@/components/SvgIcon';
import H4 from '@/components/H4';
import { ActionProps, Dialog } from '@/components/Dialog';
import { ChecklistExecutionFilter } from '@/model/ChecklistExecutionFilter';
import H6 from '@/components/H6';
import { ShouldShowModal } from '../../types';
import { FilterContent } from '../../components/FilterContent';

interface StateProps {
  status: LoaderStatus;
  title: string | React.ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  filter: ChecklistExecutionFilter;
  amountExecutions: number;
  checklistExecutionStore: ChecklistExecutionStore[];
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }) => void;
  clearFilter: () => void;
  onFilter: () => void;
}

type Props = StateProps & DispatchProps;

export const ExecutionChecklistUI: React.FC<Props> = ({
  status,
  title,
  visible,
  shouldShowModal,
  onToggle,
  filter,
  amountExecutions,
  onShouldShowModal,
  clearFilter,
  onFilter,
  checklistExecutionStore,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'secondary',
  };
  const renderActionDialogToFilter: ActionProps = {
    title: 'Aplicar',
    onClick: (): void => onFilter(),
    theme: 'primary',
  };
  const isDark = (): boolean => {
    let b = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            b = true;
            break;
          }
        }
      }
    }
    return b;
  };
  const toEventDate = (eventDate: string): string => {
    const split = eventDate.split(' ');
    const date = `${split[0]} às ${split[1].substring(0, 5)}`;
    return date;
  };
  return (
    <Fragment>
      <Loader status={status} />
      <Breadcrumbs
        mainTitle="Execuções"
        parent="Soluções"
        subParent="Checklist"
        title="Execuções"
      />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={false}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: renderActionDialogToFilter,
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: <FilterContent filter={filter} />,
          }[shouldShowModal]
        }
      </Dialog>
      <Container fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <H5>{`${amountExecutions} Checklist's executados`}</H5>
          </div>
          <div className="button-filter-container">
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal({
                    value: ShouldShowModal.filter,
                    newTitleModal: 'Filtrar',
                  });
                }}
              >
                <SvgIcon
                  iconId={isDark() ? 'fill-filter' : 'stroke-filter'}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </div>
          </div>
        </div>
        {checklistExecutionStore &&
          checklistExecutionStore.length > 0 &&
          checklistExecutionStore.map((store, ixStore) => (
            <Fragment key={`${ixStore}-Store-${store.id}`}>
              <H4>{store.name}</H4>
              {store.departments &&
                store.departments.length > 0 &&
                store.departments.map((department, ixDepartment) => (
                  <Row key={`${ixDepartment}-Department-${department.id}`} className="widget-grid">
                    <H5>{`${ixDepartment + 1} - ${department.name}`}</H5>
                    {department.cameras &&
                      department.cameras.length > 0 &&
                      department.cameras.map((camera, ixCamera) => (
                        <Fragment key={`${ixCamera}-Camera-${camera.id}`}>
                          {camera.events &&
                            camera.events.length > 0 &&
                            camera.events.map((event, ixEvent) => (
                              <Col
                                key={`${ixEvent}-Event-${event.id}`}
                                className="col-xxl-3 col-sm-3 box-col-3"
                              >
                                <div className="main-container-video-image">
                                  <img
                                    src={`https://drive.google.com/u/0/drive-usercontent/${event.fileId}=w350-h333-p-k-rw-v1-nu-iv1`}
                                    crossOrigin="anonymous"
                                    style={{ maxWidth: '100%' }}
                                  />
                                </div>
                                <div style={{ width: '100%', display: 'inline-flex' }}>
                                  <div style={{ width: '50%', textAlign: 'left' }}>
                                    <H6 className="f-14 mb-0">{camera.description}</H6>
                                  </div>
                                  <div style={{ width: '50%', textAlign: 'right' }}>
                                    <span className="f-light f-12">
                                      {toEventDate(event.eventDate)}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            ))}
                        </Fragment>
                      ))}
                  </Row>
                ))}
            </Fragment>
          ))}
      </Container>
    </Fragment>
  );
};
