import { SelectAutoComplete } from '@/components/Select';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import { InputText } from '@/components/InputText';
import { Camera } from '@/model/Camera';
import { FormInputCamera } from '../../types';

interface StateProps {
  formData: FormInputCamera;
  formErrors: FormErrors<FormInputCamera>;
  cameras: Camera[];
}

interface DispatchProps {
  onChangeInput: <N extends keyof FormInputCamera, V>(name: N, value: V) => void;
}

type Props = StateProps & DispatchProps;

export const CameraContent: React.FC<Props> = ({
  formData,
  onChangeInput,
  formErrors,
  cameras,
}): JSX.Element => {
  const camerasOptions =
    cameras && cameras.length > 0
      ? cameras.map(data => ({
          label: data.description,
          value: data.id,
        }))
      : [];

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="storeName"
              label="Loja"
              placeholder="Nome do usuário"
              value={formData.storeName}
              onChange={e => onChangeInput('storeName', e.target.value)}
              error={formErrors.storeName && formErrors.storeName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="departmentName"
              label="Departamento"
              placeholder="Nome do usuário"
              value={formData.departmentName}
              onChange={e => onChangeInput('departmentName', e.target.value)}
              error={formErrors.departmentName && formErrors.departmentName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectAutoComplete
              label="Camera"
              options={camerasOptions}
              name="cameraId"
              placeholder="Selecione a camera"
              defaultValue={formData.cameraId}
              onChange={value => onChangeInput('cameraId', value)}
              error={formErrors.cameraId && formErrors.cameraId[0]}
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};
