import { ActionProps, Dialog } from '@/components/Dialog';
import Loader, { LoaderStatus } from '@/components/Loader';
import React, { Fragment, ReactNode } from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import { FormErrors } from '@/hooks/useForm';
import { Container } from 'reactstrap';
import H5 from '@/components/H5';
import Btn from '@/components/Button';
import SvgIcon from '@/components/SvgIcon';
import { Checklist } from '@/model/Checklist';
import { CustomTable } from '@/components/Table';
import Pagination from '@/components/Pagination';
import { Page } from '@/model/Page';
import { ChecklistCommunication } from '@/model/ChecklistCommunication';
import { StatusType } from '@/model/StatusType';
import { Product } from '@/model/Product';
import { Camera } from '@/model/Camera';
import { RegisterContent } from '../../componets/RegisterContent';
import { FilterContent } from '../../componets/FilterContent';
import {
  columns,
  CommunicationDay,
  CommunicationUser,
  ConfigureChecklist,
  FormInputCamera,
  FormInputFilter,
  FormInputProduct,
  FormInputRegister,
  ModalContent,
  ShouldShowModal,
} from '../../types';
import { ChecklistContent } from '../../componets/Checklist';
import { CameraContent } from '../../componets/CameraContent';
import { ProductContent } from '../../componets/ProductContent';

interface StateProps {
  status: LoaderStatus;
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  checklists: Checklist[];
  checklistCommunicationId?: string;
  formDataRegister: FormInputRegister;
  formErrorsRegister: FormErrors<FormInputRegister>;
  communicationChecklists: ConfigureChecklist[];
  communicationUsers: CommunicationUser[];
  communicationDays: CommunicationDay[];
  executionTimes: string[];
  checklist?: Checklist;
  formDataCamera: FormInputCamera;
  formErrorsCamera: FormErrors<FormInputCamera>;
  cameras: Camera[];
  formDataProduct: FormInputProduct;
  formErrorsProduct: FormErrors<FormInputProduct>;
  products: Product[];
  registerProducts: Product[];
  formDataFilter: FormInputFilter;
  formErrorsFilter: FormErrors<FormInputFilter>;
  currentPage: Page<ChecklistCommunication, ChecklistCommunication>;
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: (content: ModalContent) => void;
  onChangeInputRegister: <N extends keyof FormInputRegister, V>(name: N, value: V) => void;
  onChangeChecklist: (value: string) => void;
  onShowChecklist: (seletedId: string, value: string) => void;
  onChangeUser: (value: string) => void;
  onChangeDay: (value: string) => void;
  onAddExecutionTime: () => void;
  onChangeExecutionTimes: (index: number, executionTime: string) => void;
  onRemoveExecutionTime: (index: number) => void;
  onSave: () => void;
  onCloseChecklist: () => void;
  onShowDeleteCamera: (storeId: string, departmentId: string, cameraId: string) => void;
  onShowDeleteProduct: (
    storeId: string,
    departmentId: string,
    cameraId: string,
    productId: string,
  ) => void;
  onChangeInputCamera: <N extends keyof FormInputCamera, V>(name: N, value: V) => void;
  onSaveCamera: () => void;
  onCloseCamera: () => void;
  onChangeInputProduct: <N extends keyof FormInputProduct, V>(name: N, value: V) => void;
  onAddRegisterProduct: () => void;
  onChangeRegisterProducts: (index: number, productId: string) => void;
  onRemoveRegisterProduct: (index: number) => void;
  onSaveProduct: () => void;
  onChangeInputFilter: <N extends keyof FormInputFilter, V>(name: N, value: V) => void;
  onClearFilter: () => void;
  onFilter: () => void;
  onPaginationChange: (page: number) => void;
  onShowDelete: (checklistCommunication: ChecklistCommunication) => void;
}

type Props = StateProps & DispatchProps;

export const CommunicationChecklistUI: React.FC<Props> = ({
  status,
  title,
  visible,
  onToggle,
  shouldShowModal,
  onShouldShowModal,
  checklistCommunicationId,
  formDataRegister,
  onChangeInputRegister,
  formErrorsRegister,
  communicationChecklists,
  onChangeChecklist,
  onShowChecklist,
  communicationUsers,
  onChangeUser,
  communicationDays,
  onChangeDay,
  executionTimes,
  onAddExecutionTime,
  onChangeExecutionTimes,
  onRemoveExecutionTime,
  onSave,
  onCloseChecklist,
  checklist,
  onShowDeleteCamera,
  onShowDeleteProduct,
  formDataCamera,
  onChangeInputCamera,
  formErrorsCamera,
  cameras,
  onSaveCamera,
  onCloseCamera,
  formDataProduct,
  formErrorsProduct,
  onChangeInputProduct,
  products,
  registerProducts,
  onAddRegisterProduct,
  onChangeRegisterProducts,
  onRemoveRegisterProduct,
  formDataFilter,
  onChangeInputFilter,
  formErrorsFilter,
  onClearFilter,
  onFilter,
  currentPage,
  onPaginationChange,
  onShowDelete,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'secondary',
  };
  const renderActionDialogToFilter: ActionProps = {
    title: 'Aplicar',
    onClick: (): void => onFilter(),
    theme: 'primary',
  };

  const renderActionDialogToCancelRegister: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'secondary',
  };

  const renderActionDialogToRegister: ActionProps = {
    title: 'Salvar',
    onClick: (): void => onSave(),
    theme: 'primary',
  };

  const renderActionDialogToCloseChecklist: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onCloseChecklist(),
    theme: 'secondary',
  };

  const renderActionDialogToCancelCamera: ActionProps = {
    title: 'Voltar',
    onClick: (): void => onCloseCamera(),
    theme: 'secondary',
  };

  const renderActionDialogToRegisterCamera: ActionProps = {
    title: 'Salvar',
    onClick: (): void => onSaveCamera(),
    theme: 'primary',
  };

  const isDark = (): boolean => {
    let b = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            b = true;
            break;
          }
        }
      }
    }
    return b;
  };

  const toStatus = (selectedStatus: StatusType): string => {
    let s = '';
    switch (selectedStatus) {
      case StatusType.ACTIVE:
        s = 'Ativo';
        break;
      case StatusType.INACTIVE:
        s = 'Inativo';
        break;
      default:
        s = '';
        break;
    }
    return s;
  };

  const dataTable =
    currentPage && currentPage.list && currentPage.list.length > 0
      ? currentPage.list.map(data => ({
          description: data.description,
          status: toStatus(data.status),
          actions: (
            <div className="d-flex" style={{ height: '20px' }}>
              <SvgIcon
                iconId={isDark() ? 'fill-pen' : 'stroke-pen'}
                className="mr-4 svg-icon action-icon"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.REGISTER,
                    newTitleModal: data.description,
                    selectedId: data.id,
                    toogleModal: true,
                  });
                }}
                style={{
                  marginRight: '4px',
                }}
              />
              <SvgIcon
                iconId={isDark() ? 'fill-trash' : 'stroke-trash'}
                className="mr-4 svg-icon action-icon"
                onClick={(): void => {
                  onShowDelete(data);
                }}
              />
            </div>
          ),
        }))
      : [];

  return (
    <Fragment>
      <Loader status={status} />
      <Breadcrumbs
        mainTitle="Configurar Comunicações"
        parent="Soluções"
        subParent="Checklist"
        title="Comunicação"
      />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.FILTER ? 'right' : 'center'}
        isContentWithCard={false}
        actions={[
          {
            [ShouldShowModal.FILTER]: renderActionDialogToCancelFilter,
            [ShouldShowModal.REGISTER]: renderActionDialogToCancelRegister,
            [ShouldShowModal.CHECKLIST]: renderActionDialogToCloseChecklist,
            [ShouldShowModal.CAMERA]: renderActionDialogToCancelCamera,
            [ShouldShowModal.PRODUCT]: renderActionDialogToCancelRegister,
          }[shouldShowModal],
          {
            [ShouldShowModal.FILTER]: renderActionDialogToFilter,
            [ShouldShowModal.REGISTER]: renderActionDialogToRegister,
            [ShouldShowModal.CHECKLIST]: {},
            [ShouldShowModal.CAMERA]: renderActionDialogToRegisterCamera,
            [ShouldShowModal.PRODUCT]: renderActionDialogToRegister,
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.FILTER]: (
              <FilterContent
                formData={formDataFilter}
                onChangeInput={onChangeInputFilter}
                formErrors={formErrorsFilter}
              />
            ),
            [ShouldShowModal.REGISTER]: (
              <RegisterContent
                checklistCommunicationId={checklistCommunicationId as string}
                formData={formDataRegister}
                formErrors={formErrorsRegister}
                onChangeInput={onChangeInputRegister}
                communicationChecklists={communicationChecklists}
                onChangeChecklist={onChangeChecklist}
                onShowChecklist={onShowChecklist}
                communicationUsers={communicationUsers}
                onChangeUser={onChangeUser}
                communicationDays={communicationDays}
                onChangeDay={onChangeDay}
                executionTimes={executionTimes}
                onAddExecutionTime={onAddExecutionTime}
                onChangeExecutionTimes={onChangeExecutionTimes}
                onRemoveExecutionTime={onRemoveExecutionTime}
              />
            ),
            [ShouldShowModal.CHECKLIST]: (
              <ChecklistContent
                checklistCommunicationId={checklistCommunicationId}
                checklist={checklist}
                onShouldShowModal={onShouldShowModal}
                onShowDeleteCamera={onShowDeleteCamera}
                onShowDeleteProduct={onShowDeleteProduct}
              />
            ),
            [ShouldShowModal.CAMERA]: (
              <CameraContent
                formData={formDataCamera}
                onChangeInput={onChangeInputCamera}
                formErrors={formErrorsCamera}
                cameras={cameras}
              />
            ),
            [ShouldShowModal.PRODUCT]: (
              <ProductContent
                formData={formDataProduct}
                onChangeInput={onChangeInputProduct}
                formErrors={formErrorsProduct}
                products={products}
                registerProducts={registerProducts}
                onAdd={onAddRegisterProduct}
                onChange={onChangeRegisterProducts}
                onRemove={onRemoveRegisterProduct}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <H5>Comunicações</H5>
          </div>
          <div className="button-filter-container">
            <Btn
              size="md"
              attrBtn={{
                onClick: (): void => {
                  onShouldShowModal({
                    value: ShouldShowModal.REGISTER,
                    newTitleModal: 'Cadastrar',
                    toogleModal: true,
                  });
                },
              }}
            >
              + Cadastrar
            </Btn>
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal({
                    value: ShouldShowModal.FILTER,
                    newTitleModal: 'Filtrar',
                    toogleModal: true,
                  });
                }}
              >
                <SvgIcon
                  iconId={isDark() ? 'fill-filter' : 'stroke-filter'}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={dataTable}
          numberRowsPerPage={currentPage.pageSize}
          progressPending={status === LoaderStatus.LOADING}
        />
        <Pagination
          currentPage={currentPage.page}
          totalCount={currentPage.total}
          pageSize={currentPage.pageSize}
          onPageChange={page => onPaginationChange(page)}
          total={currentPage.total}
        />
      </Container>
    </Fragment>
  );
};
