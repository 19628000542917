import SvgIcon from '@/components/SvgIcon';
import { Checklist } from '@/model/Checklist';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { ModalContent, ShouldShowModal } from '../../types';
import './styles.scss';

interface StateProps {
  checklistCommunicationId?: string;
  checklist?: Checklist;
}

interface DispatchProps {
  onShouldShowModal: (content: ModalContent) => void;
  onShowDeleteCamera: (storeId: string, departmentId: string, cameraId: string) => void;
  onShowDeleteProduct: (
    storeId: string,
    departmentId: string,
    cameraId: string,
    productId: string,
  ) => void;
}

type Props = StateProps & DispatchProps;

export const ChecklistContent: React.FC<Props> = ({
  checklistCommunicationId,
  checklist,
  onShouldShowModal,
  onShowDeleteCamera,
  onShowDeleteProduct,
}): JSX.Element => {
  const isDark = (): boolean => {
    let b = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            b = true;
            break;
          }
        }
      }
    }
    return b;
  };
  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <Row>
        <Col>
          <p className="text-title-gruop">Nome do depatamento</p>
        </Col>
      </Row>
      <Row className="tree-container">
        {checklist &&
          checklist.departments &&
          checklist.departments.length > 0 &&
          checklist.departments.map((department, ixD) => (
            <Col key={`department-${ixD}`} className="tree-item-container">
              <div className="d-flex">
                <div className="d-flex justify-content-between text-gruop tree-main-text">
                  <div className="mv-auto">{department.name}</div>
                </div>
                <div className="text-success-link-widht">
                  <a
                    className="text-success-link"
                    onClick={(): void =>
                      onShouldShowModal({
                        value: ShouldShowModal.CAMERA,
                        newTitleModal: `${department.name}`,
                        selectedId: checklistCommunicationId,
                        storeId: checklist.store.id,
                        departmentId: department.id,
                        toogleModal: false,
                      })
                    }
                  >
                    + cadastrar nova câmera
                  </a>
                </div>
              </div>
              {department.cameras && department.cameras.length > 0 && (
                <div className="tree">
                  <ul>
                    {department.cameras.map((camera, ixC) => (
                      <li key={`camera-${ixC}`}>
                        <div>{camera.description}</div>
                        <div className="flex-shrink-0 ml-2">
                          <SvgIcon
                            iconId={isDark() ? 'fill-trash' : 'stroke-trash'}
                            className="mr-4 svg-icon action-icon"
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                            onClick={(): void =>
                              onShowDeleteCamera(checklist.store.id, department.id, camera.id)
                            }
                          />
                          <div className="text-success-link-widht" style={{ display: 'inline' }}>
                            <a
                              className="text-success-link"
                              onClick={(): void =>
                                onShouldShowModal({
                                  value: ShouldShowModal.PRODUCT,
                                  newTitleModal: `${department.name}`,
                                  storeId: checklist.store.id,
                                  departmentId: department.id,
                                  cameraId: camera.id,
                                  cameraName: camera.description,
                                  toogleModal: false,
                                })
                              }
                            >
                              + novo produto
                            </a>
                          </div>
                          {camera.products && camera.products.length > 0 && (
                            <div className="tree">
                              <ul>
                                {camera.products.map((product, ixP) => (
                                  <li key={`${ixP}-product-${product.id}`}>
                                    <div>{product.name}</div>
                                    <div className="flex-shrink-0 ml-2">
                                      <SvgIcon
                                        iconId={isDark() ? 'fill-pen' : 'stroke-pen'}
                                        className="mr-4 svg-icon action-icon"
                                        style={{
                                          marginRight: '4px',
                                          width: '20px',
                                          height: '20px',
                                        }}
                                        onClick={(): void =>
                                          onShouldShowModal({
                                            value: ShouldShowModal.PRODUCT,
                                            newTitleModal: `${department.name}`,
                                            storeId: checklist.store.id,
                                            departmentId: department.id,
                                            cameraId: camera.id,
                                            cameraName: camera.description,
                                            toogleModal: false,
                                          })
                                        }
                                      />
                                      <SvgIcon
                                        iconId={isDark() ? 'fill-trash' : 'stroke-trash'}
                                        className="mr-4 svg-icon action-icon"
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                        }}
                                        onClick={(): void =>
                                          onShowDeleteProduct(
                                            checklist.store.id,
                                            department.id,
                                            camera.id,
                                            product.id,
                                          )
                                        }
                                      />
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Col>
          ))}
      </Row>
    </div>
  );
};
