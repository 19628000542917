import { Camera } from '@/model/Camera';
import { Checklist } from '@/model/Checklist';
import { ChecklistCommunication } from '@/model/ChecklistCommunication';
import { ChecklistCommunicationCamera } from '@/model/ChecklistCommunicationCamera';
import { Page } from '@/model/Page';
import { SimpleStore } from '@/model/SimpleStore';
import { User } from '@/model/User';
import api from '@/services/api';

export const pageChecklistCommunication = async (
  request: Page<ChecklistCommunication, ChecklistCommunication>,
): Promise<Page<ChecklistCommunication, ChecklistCommunication>> => {
  const { data } = await api.post<Page<ChecklistCommunication, ChecklistCommunication>>(
    '/checklist-communication/v1/page',
    request,
  );
  return data;
};

export const getStores = async (): Promise<SimpleStore[]> => {
  const { data } = await api.get<SimpleStore[]>('/store-event-custom/v1/store/get');
  return data;
};

export const getUsers = async (): Promise<User[]> => {
  const { data } = await api.get<User[]>('/user/v1/all');
  return data;
};

export const getChecklistCommunication = async (id: string): Promise<ChecklistCommunication> => {
  const { data } = await api.get<ChecklistCommunication>(`/checklist-communication/v1/${id}`);
  return data;
};

export const saveChecklistCommunication = async (
  payload: ChecklistCommunication,
): Promise<void> => {
  if (payload.id) {
    await api.put('/checklist-communication/v1', payload);
  } else {
    await api.post('/checklist-communication/v1', payload);
  }
};

export const deleteChecklistCommunication = async (id: string): Promise<void> => {
  await api.delete(`/checklist-communication/v1/${id}`);
};

export const getChecklist = async (
  checklistCommunicationId: string,
  storedId: string,
  departmentId: string,
): Promise<Checklist> => {
  const { data } = await api.get(
    `/checklist-communication/v1/checklist/${checklistCommunicationId}/store/${storedId}/department/${departmentId}`,
  );
  return data;
};

export const getChecklistCommunicationCameras = async (
  checklistCommunicationId: string,
  storedId: string,
  departmentId: string,
): Promise<Camera[]> => {
  const { data } = await api.get(
    `/checklist-communication/v1/camera/${checklistCommunicationId}/store/${storedId}/department/${departmentId}`,
  );
  return data;
};

export const saveChecklistCommunicationCamera = async (
  payload: ChecklistCommunicationCamera,
): Promise<Camera> => {
  const { data } = await api.post('/checklist-communication/v1/camera', payload);
  return data;
};

export const removeChecklistCommunicationCamera = async (
  checklistCommunicationId: string,
  storedId: string,
  departmentId: string,
  cameraId: string,
): Promise<Camera> => {
  const { data } = await api.delete(
    `/checklist-communication/v1/${checklistCommunicationId}/store/${storedId}/department/${departmentId}/camera/${cameraId}`,
  );
  return data;
};
