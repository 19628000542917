import React from 'react';
import { DashboardScreen } from '@/feature/dashboard/screens';
import { CancelItemScreen } from '@/feature/cancelItem/screens';
import { CancelCouponScreen } from '@/feature/cancelCoupon/screens';
import { DiscountScreen } from '@/feature/discount/screens';
import { ReturnCouponScreen } from '@/feature/returnCoupon/screens';
import { BleedingScreen } from '@/feature/bleeding/screens';
import { CompanyEventCustomScreen } from '@/feature/companyEventCustom/screens';
import { StoreEventCustomScreen } from '@/feature/storeEventCustom/screens';
import { CustomEventScreen } from '@/feature/customEvent/screens';
import { UserScreen } from '@/feature/user/screens';
import { IndicatorScreen } from '@/feature/indicator/screens';
import { ConfigureChecklistScreen } from '@/feature/configureChecklist/screens';
import { ExecutionChecklistScreen } from '@/feature/executionChecklist/screens';
import { CommunicationChecklistScreen } from '@/feature/communicationChecklist/screens';

export const routes = [
  { path: `/dashboard`, Component: <DashboardScreen /> },
  { path: `/indicators`, Component: <IndicatorScreen /> },
  { path: `/solutions/safe-checkout/cancel-item`, Component: <CancelItemScreen /> },
  { path: `/solutions/safe-checkout/cancel-coupon`, Component: <CancelCouponScreen /> },
  { path: `/solutions/safe-checkout/custom-event`, Component: <CustomEventScreen /> },
  { path: `/solutions/safe-checkout/discount`, Component: <DiscountScreen /> },
  { path: `/solutions/safe-checkout/return-coupon`, Component: <ReturnCouponScreen /> },
  { path: `/solutions/safe-checkout/bleeding`, Component: <BleedingScreen /> },
  { path: `/solutions/checklist/configure`, Component: <ConfigureChecklistScreen /> },
  { path: `/solutions/checklist/execution`, Component: <ExecutionChecklistScreen /> },
  { path: `/solutions/checklist/communication`, Component: <CommunicationChecklistScreen /> },
  { path: `/parameters/custom-event/company`, Component: <CompanyEventCustomScreen /> },
  { path: `/parameters/custom-event/store`, Component: <StoreEventCustomScreen /> },
  { path: `/parameters/user`, Component: <UserScreen /> },
];
